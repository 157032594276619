import React, { useEffect, useState } from 'react';
import {
    DataGridPro,
    GridToolbarColumnsButton, GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import apiService from '../api/apiService';
import { hazardRiskScore } from '../constants/hazardConstants';
import HazardEditMenuButton from './Hazard/HazardEditMenuButton';
import Box from '@mui/material/Box';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateHazardFilters, updateHazardColumns } from '../store/correcttrackSlice';

const Hazards = () => {
    const [hazards, setHazards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [componentKey, setComponentKey] = useState(0);
    const [currentHazard, setCurrentHazard] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [currentFilters, setCurrentFilters] = useState(useSelector((state) => state.correcttrack.hazardFilters));
    const [currentVisibleFields, setCurrentVisibleFields] = useState(useSelector((state) => state.correcttrack.hazardColumns));

    const dispatch = useDispatch();

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    function updateFilters(filterModel) {
        if(!loading) {
            setCurrentFilters(filterModel);
            dispatch(updateHazardFilters(filterModel));
        }
    }

    function updateVisibleColumns(columnsModel) {
        if(!loading) {
            setCurrentVisibleFields(columnsModel);
            dispatch(updateHazardColumns(columnsModel));
        }
    }

    const [columns] = useState([
        { field: 'hzId', headerName: 'Risk ID', flex: 1 },
        {
            field: 'hzOrganizationName',
            headerName: 'Organization',
            flex: 1,
            valueGetter: getOrganizationName,
        },
        {
            field: 'hzProject',
            headerName: 'Project',
            flex: 1,
            valueGetter: getProjectName,
        },
        {
            field: 'hzRiskSource',
            headerName: 'Risk Source',
            minWidth: 110,
            flex: 1,
            valueGetter: getRiskSource,
        },
        { field: 'hzDepartmentName', headerName: 'Dept.', flex: 1 },
        { field: 'hzProcess', headerName: 'Process', flex: 1 },
        { field: 'hzJob', headerName: 'Job', flex: 1 },
        { field: 'hzTask', headerName: 'Task', minWidth: 90, flex: 1 },
        { field: 'hzEquipmentId', headerName: 'Equipment ID', flex: 1 },
        { field: 'hzRiskDescription', headerName: 'Risk Description', flex: 1 },
        { field: 'hzRiskScoreUncontrolled', headerName: 'Risk Score Uncontrolled', flex: 1.5 },
        { field: 'hzRiskLevelUncontrolled', headerName: 'Risk Level Uncontrolled', flex: 1.5 },
        { field: 'hzRiskScoreControlled', headerName: 'Risk Score Controlled', flex: 1.5, valueGetter: (params) => parseInt(params.row.hzRiskScoreControlled) },
        { field: 'hzRisKLevelControlled', headerName: 'Risk Level Controlled', flex: 1.5 },
        {
            field: 'significant',
            headerName: 'Significant',
            flex: 1,
            renderCell: (params) => (params.row.significant ? 'Yes' : 'No'),
            valueGetter: (params) => (params.row.significant ? 'Yes' : 'No'),
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <HazardEditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedHazard={setCurrentHazard}
                />;
            },
        },
    ]);

    const dialogTitle = () => {
        return 'Confirm Delete Risk ' + currentHazard?.hzId;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Risk ' + currentHazard?.hzId + '?';
    };

    const onDeleteHazardClick = (deleteValue) => {
        if (deleteValue) {
            apiService.deleteData('api/Hazard/' + currentHazard.hzId).then(() => {
                const componentKeyAdd = componentKey + 1;
                setComponentKey(componentKeyAdd);
            });
        }

        setShowDeleteDialog(false);
    };

    function getOrganizationName (params) {
        return params.row.hzProjectDto?.pjOrgDto.ogName;
    }

    function getProjectName (params) {
        return params.row.hzProjectDto?.pjName;
    }

    function getRiskSource (params) {
        return params.row.hzSystemAreaDto?.name;
    }

    function setRiskValues (data) {
        data.hzRiskScoreUncontrolled = data.hzSeverity * data.hzLikelihood;

        data.hzRiskLevelUncontrolled = getRiskScoreValue(data.hzRiskScoreUncontrolled);

        // Now calculate the controlled values
        // For each control, multiply the factor to the uncontrolled to get the controlled score
        data.hzRiskScoreControlled = data.hzRiskScoreUncontrolled.toFixed(2);
        data.hazardControlDtos.forEach(hazardControl => {
            const controlFactor = hazardControl.hcControlTypeNavigation.ctFactor;
            data.hzRiskScoreControlled = (data.hzRiskScoreControlled * controlFactor).toFixed(2);
        });

        data.hzRisKLevelControlled = getRiskScoreValue(data.hzRiskScoreControlled);
    }

    function getRiskScoreValue (value) {
        if (value < hazardRiskScore.LOW) {
            return 'Low';
        } else if (value < hazardRiskScore.MEDIUM) {
            return 'Medium';
        } else if (value < hazardRiskScore.HIGH) {
            return 'High';
        } else {
            return 'Very High';
        }
    }

    useEffect(() => {
        apiService.getData('api/Hazard').then(async (data) => {
            const hazardData = data.data.data;

            const likelihoodResponse = await apiService.getData('api/Likelihood');
            const severityResponse = await apiService.getData('api/Severity');

            const likelihoodList = likelihoodResponse.data.data;
            const severityList = severityResponse.data.data;

            hazardData.forEach((hazard) => {
                // Set the likelihood and severity values
                const newLikelihood = likelihoodList.find(likely => likely.lkId === hazard.hzLikelihood)?.lkFactor;
                const newSeverity = severityList.find(sev => sev.svId === hazard.hzSeverity)?.svFactor;

                if (newLikelihood) {
                    hazard.hzLikelihood = newLikelihood;
                }

                if (newSeverity) {
                    hazard.hzSeverity = newSeverity;
                }

                setRiskValues(hazard);
            });

            setHazards(hazardData);
            setLoading(false);
        });
    }, [componentKey]);

    return (
        <Box sx={{
            height: '90vh',
            width: '100%',
            '& .VeryHigh': {
                backgroundColor: '#FF0000',
                color: '#1a3e72',
            },
            '& .High': {
                backgroundColor: '#FFA500',
                color: '#1a3e72',
            },
            '& .Medium': {
                backgroundColor: '#FFFF00',
                color: '#1a3e72',
            },
            '& .Low': {
                backgroundColor: '#00FF00',
                color: '#1a3e72',
            },
        }}>
            <Button
                variant="contained"
                href="/Hazard"
                sx={{ marginBottom: '16px', marginTop: '16px' }}
            >
                Create New Risk
            </Button>
            <DataGridPro
                rows={hazards}
                columns={columns}
                loading={loading}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                    Toolbar: CustomToolbar,
                }}
                filterModel={currentFilters}
                onFilterModelChange={(newFilterModel) => updateFilters(newFilterModel)}
                columnVisibilityModel={currentVisibleFields}
                onColumnVisibilityModelChange={(newColumnsModel) => updateVisibleColumns(newColumnsModel)}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                getCellClassName={(params) => {
                    if ((params.field !== 'hzRiskLevelUncontrolled' && params.field !== 'hzRisKLevelControlled') || params.value == null) {
                        return '';
                    }

                    if (params.value === 'Very High') {
                        return 'VeryHigh';
                    }

                    return params.value;
                }}
                getRowId={(row) => row.hzId}
            />

            <ConfirmDeleteDialogHazard
                id="confirm-delete"
                keepMounted
                value={currentHazard}
                open={showDeleteDialog}
                onClose={onDeleteHazardClick}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </Box>
    );
};
export default Hazards;
