import axios from './CustomAxios';

export class ApiService {
    originURL = window.location.origin;

    /**
     * Sends an HTTP POST request
     * @param url URL to call
     * @param payload {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async postData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return await axios.post(`${this.originURL}/${url}`, JSON.stringify(payload), _headers)
            .catch(err => {
                return err.response;
            });
    }

    async postFormData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        };

        return await axios.post(`${this.originURL}/${url}`, payload, _headers)
            .catch(err => {
                return err.response;
            });
    }

    /**
     * Sends an HTTP PUT request
     * @param url URL to call
     * @param payload {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async putData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return await axios.put(`${this.originURL}/${url}`, JSON.stringify(payload), _headers)
            .catch(err => {
                return err.response;
            });
    }

    /**
     * Sends an HTTP GET request
     * @param url URL to call
     * @param headers {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async getData (url, headers) {
        let _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        if (headers) {
            _headers = { ..._headers, ...headers };
        }

        return await axios.get(`${this.originURL}/${url}`, _headers)
            .catch(err => {
                return err.response;
            });

    }

    /**
     * Sends an HTTP DELETE request
     * @param url {string}
     * @param payload {object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async deleteData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        const config = { ..._headers };

        if (payload) {
            config.data = payload;
        }

        return await axios.delete(`${this.originURL}/${url}`, config)
            .catch(err => {
                return err.response;
            });
    }
}
const apiService = new ApiService();
export default apiService;
