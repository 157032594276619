export default class RiskSourceDto {
    /**
     * @type{number}
     */
    id;

    /**
     * @type{string}
     */
    name;
}
