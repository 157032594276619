import axios from 'axios';
import AuthUtil from '../components/Utils/AuthUtil';
import { logout, updateUserToken } from '../store/correcttrackSlice';

const axiosInstance = axios.create();

let isRefreshing = false;
let failedQueue = [];

let store;

export const injectStore = _store => {
    store = _store;
};

function processQueue (error, token=null) {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        }
        else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
}

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const state = store.getState();
            const refreshToken = state.correcttrack.refreshToken;
            const userToken = state.correcttrack.userToken;

            const username = AuthUtil.getUsername(userToken);

            return new Promise(function (resolve, reject) {
                axios.post('/api/Auth/refresh', {
                    refreshToken: refreshToken, username: username,
                })
                    .then(({ data }) => {
                        if(data && data.data) {
                            store.dispatch(updateUserToken(data.data)); // Save the new token to Redux store
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.data.token;
                            processQueue(null, data.data.token);
                            resolve(axios(originalRequest));
                        }
                        else {
                            store.dispatch(logout());
                        }

                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                        store.dispatch(logout());
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    },
);

axiosInstance.interceptors.request.use(
    config => {
        const state = store.getState();
        const token = state.correcttrack.userToken;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error),
);

export default axiosInstance;
