import apiService from './apiService';

export default class RiskSourceApiService {
    /**
     * Gets All Risk Sources
     * @return {Promise<ServiceResponse<Array<RiskSourceDto>>>}
     */
    async getRiskSources () {
        const riskSources = await apiService.getData('api/RiskSource');
        return riskSources.data;
    }

    /**
     * Add a new Risk Source
     * @param riskSourceDto {RiskSourceDto}
     * @return {Promise<ServiceResponse<RiskSourceDto>>}
     */
    async createRiskSource(riskSourceDto) {
        const newId = await apiService.postData('api/RiskSource', riskSourceDto);

        return newId.data;
    }

    /**
     * Update a Risk Source
     * @param riskSourceDto {RiskSourceDto}
     * @return {Promise<ServiceResponse<RiskSourceDto>>}
     */
    async updateRiskSource(riskSourceDto) {
        const updatedRiskSource = await apiService.putData('api/RiskSource', riskSourceDto);

        return updatedRiskSource.data;
    }

    /**
     * Delete a Risk Source
     * @param riskSourceId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteRiskSource(riskSourceId) {
        const deletedRiskSource = await apiService.deleteData('api/RiskSource/' + riskSourceId);

        return deletedRiskSource.data;
    }
}
