import apiService from '../api/apiService';
import { updateUserToken } from '../store/correcttrackSlice';
import AuthUtil from '../components/Utils/AuthUtil';

let store;

export const injectStoreForAuth = _store => {
    store = _store;
};

class AuthService {
    _baseUrl = 'api/Auth/';

    async login (username, password) {
        const result = await apiService.postData(this._baseUrl + '/login', {
            username,
            password,
        });
        if (result.status === 200 && result.data.success) {
            store.dispatch(updateUserToken(result.data.data));
        }
        return result;
    }

    async logout () {
        const user = AuthUtil.getUsername(store.getState().correcttrack.userToken);
        const refreshToken = store.getState().correcttrack.refreshToken;

        const result = await apiService.postData(this._baseUrl + '/logout', {
            username: user,
            refreshtoken: refreshToken,
        });
        await result;
        if (result.status === 200) {
            return true;
        }
        return false;
    }

    /**
     * Request a password reset
     * @param username {string}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async requestResetPassword(username) {
        const result = await apiService.postData(`${this._baseUrl}/reset-password/${username}`, null);

        return result.data;
    }

    /**
     * Submit password reset
     * @param passwordRequest {PasswordResetSubmissionDTO}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async submitChangedPassword(passwordRequest) {
        const result = await apiService.postData(`${this._baseUrl}/reset-user-password`, passwordRequest);

        return result.data;
    }
}

const authService = new AuthService();
export default authService;
