import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, FormLabel, TextField } from '@mui/material';
import { Row } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import RiskSourceDto from '../../../dtos/Hazards/RiskSourceDto';
import RiskSourceApiService from '../../../api/RiskSourceApiService';

export default function ModifyRiskSourceModal (props) {
    const { onClose, open, selectedRiskSource, setSnackbarProperties, riskSourceApiService, onCancel } = props;

    const [riskSourceName, setRiskSourceName] = useState('');

    const [nameError, setNameError] = useState('');

    async function handleSave() {
        const riskSourceDto = new RiskSourceDto();
        riskSourceDto.id = selectedRiskSource?.id ?? 0;
        riskSourceDto.name = riskSourceName;

        if(validateInfo() && selectedRiskSource && selectedRiskSource.ctId) {
            const updatedRiskSource = await riskSourceApiService.updateRiskSource(riskSourceDto);

            if(updatedRiskSource.success) {
                setSnackbarProperties('Updated Risk Source');
                resetModal();
            } else {
                setSnackbarProperties('Unable to update Risk Source: ' + updatedRiskSource.message, 'error');
            }
        } else if(validateInfo()) {
            const newRiskSource = await riskSourceApiService.createRiskSource(riskSourceDto);

            if(newRiskSource.success) {
                setSnackbarProperties('Added new Risk Source');
                resetModal();
            } else {
                setSnackbarProperties('Unable to add new Risk Source: ' + newRiskSource.message, 'error');
            }
        }
    }

    function validateInfo() {
        let errorMessage = '';

        if(riskSourceName === null || riskSourceName === '') {
            errorMessage = 'Name is required';
            setNameError('Name must not be empty');
        }

        setNameError(!riskSourceName);

        if(errorMessage.length > 0) {
            setSnackbarProperties('Risk Source validation failed: ' + errorMessage, 'error');
            return false;
        } else {
            return true;
        }
    }

    function resetModal() {
        setRiskSourceName('');
        onClose();
    }

    useEffect(() => {
        if(open) {
            setRiskSourceName(selectedRiskSource?.ctName ?? '');
        }
    }, [selectedRiskSource, open])

    return(
        <Dialog
            open={open}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>{selectedRiskSource?.ctName ? 'Edit ' + selectedRiskSource.ctName : 'Add New Risk Source'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Row>
                        <FormLabel>Risk Source Name</FormLabel>
                        <br/>
                        <TextField
                            error={!!nameError}
                            sx={{ width: '100%' }}
                            autoComplete={'Risk Source Name'}
                            value={riskSourceName}
                            onChange={(event) => setRiskSourceName(event.target.value)}
                        />
                    </Row>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant={'contained'} onClick={onCancel}>Cancel</Button>
                <Button variant={'contained'} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

ModifyRiskSourceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedRiskSource: PropTypes.objectOf(RiskSourceDto),
    setSnackbarProperties: PropTypes.func.isRequired,
    riskSourceApiService: PropTypes.objectOf(RiskSourceApiService).isRequired,
    onCancel: PropTypes.func.isRequired,
}
