import PropTypes from 'prop-types';
import ResourceDto from '../../../dtos/Resources/ResourceDto';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { CardMedia, FormLabel, GlobalStyles, Grid, Select, TextField } from '@mui/material';
import ResourceTypeDto from '../../../dtos/Resources/ResourceTypeDto';
import ResourceTopicDto from '../../../dtos/Resources/ResourceTopicDto';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ResourceApiService from '../../../api/ResourceApiService';
import * as resourceConstants from '../../../constants/resourceConstants';
import FileUploadModal from '../FileUploadModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, List, Heading, Indent, MediaEmbed, Table, FontFamily, FontSize, BlockQuote, AutoLink, LinkEditing, LinkUI } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

const ResourceTitle = 'title';
const ResourceDescription = 'description';

const inputGlobalStyles = (
    <GlobalStyles
        styles={{
            ".ck.ck-balloon-panel": {
                zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
            },
        }}
    />
);

export default function EditResourceModal(props) {
    const { resource, setResource, open, types, topics, cancel, resourceApiService, setSnackbarProperties } = props;

    const[showAttachmentModal, setShowAttachmentModal] = useState(false);
    const[attachmentType, setAttachmentType] = useState('');
    const[selectedTopics, setSelectedTopics] = useState([]);

    function changeResourceType(event) {
        const resourceCopy = {...resource};

        resourceCopy.rsResourceTypeId = event.target.value;
        setResource(resourceCopy);
    }

    async function handleFileUpload (event) {
        if (event) {
            const attachmentResponse = await resourceApiService.addResourceAttachment(event);
            if(attachmentResponse.success) {
                const resourceCopy = {...resource};
                if(attachmentType === resourceConstants.ImageAttachment) {
                    resourceCopy.resourceImage = attachmentResponse.data;
                } else if (attachmentType === resourceConstants.FileAttachment) {
                    resourceCopy.resourceAttachment = attachmentResponse.data;
                }
                setResource(resourceCopy);
            }
        }

        setShowAttachmentModal(false);
    }

    function updateFieldByName(event, fieldName) {
        const resourceCopy = {...resource};

        switch (fieldName) {
        case ResourceTitle:
            resourceCopy.rsTitle = event.target.value;
            break;
        case ResourceDescription:
            resourceCopy.rsShortDescription = event.target.value;
            break;
        }

        setResource(resourceCopy);
    }

    function updateResourceContent(data) {
        const resourceCopy = {...resource};

        resourceCopy.rsContent = data;
        setResource(resourceCopy);
    }

    async function saveResource() {
        if(resource.rsId > 0) {
            const update = await resourceApiService.updateResource(resource);
            if(update.success) {
                setSnackbarProperties('Successfully updated resource');
                cancel();
            } else {
                setSnackbarProperties('Error updating resource: ' + update.message, 'error');
            }
        } else {
            const addResource = await resourceApiService.addResource(resource);
            if(addResource.success) {
                setSnackbarProperties('Successfully added resource');
                setResource(null);
                cancel();
            } else {
                setSnackbarProperties('Error adding resource: ' + addResource.message, 'error');
            }
        }

    }

    function changeResourceTopics(event) {
        const { options } = event.target;
        const value = [];
        for (let i = 0; i < options.length; i++) {
            if(options[i].selected) {
                value.push(topics.find((topic) => topic.id == options[i].value))
            }
        }

        const resourceCopy = {...resource};

        resourceCopy.resourceTopics = value;
        setResource(resourceCopy);
    }

    function parseResourceTopicsForSelect() {
        let parsedSelectedTopics = [];

        resource?.resourceTopics?.forEach((value) => {

            parsedSelectedTopics.push(value.id);
        });

        return parsedSelectedTopics;
    }

    useEffect(() => {
        if(open && resource) {
            setSelectedTopics(parseResourceTopicsForSelect());
        }
    }, [open, resource]);

    return(
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '150%' } }}
            maxWidth="md"
            fullWidth
            open={open}
            disableEnforceFocus={true}
        >
            <DialogTitle>{resource?.rsId > 0 ? 'Edit ' + resource.rsTitle : 'Add New Resource'}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item md={12}>
                        <FormLabel>Title</FormLabel>
                        <br/>
                        <TextField
                            sx={{width: '100%'}}
                            value={resource?.rsTitle}
                            onChange={(event) => updateFieldByName(event, ResourceTitle)}
                        />
                    </Grid>
                    <Grid item md={12} sx={{marginTop: 4}}>
                        <FormLabel>Resource Description</FormLabel>
                        <br/>
                        <TextField
                            sx={{width: '100%'}}
                            value={resource?.rsShortDescription}
                            onChange={(event) => updateFieldByName(event, ResourceDescription)}
                        />
                    </Grid>
                    <Grid item md={12} sx={{marginTop: 4}}>
                        <FormLabel>Resource Type</FormLabel>
                        <br/>
                        <Select
                            sx={{width: '100%'}}
                            value={resource?.rsResourceTypeId}
                            onChange={(event) => changeResourceType(event)}
                        >
                            {types.map((type) => {
                                return <MenuItem key={type.ryId} value={type.ryId}>{type.ryName}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid item md={12} sx={{marginTop: 4 }}>
                        <FormLabel>Resource Topics</FormLabel>
                        <br/>
                        <select
                            style={{width: '100%'}}
                            multiple
                            value={selectedTopics}
                            onChange={(event) => changeResourceTopics(event)}
                        >
                            {topics.map((value) => <option key={value.id} value={value.id}>{value.name}</option>)}
                        </select>
                    </Grid>
                    <Grid item md={12} sx={{
                        marginTop: '4px',
                        ".ck-editor__editable_inline": {
                            height: "200px",
                            border: (theme) =>
                                theme.palette.mode === "light"
                                    ? "1px solid rgba(0, 0, 0, 0.20) !important"
                                    : "1px solid rgba(200, 200, 200, 0.25) !important",
                            borderTop: "1px !important",
                        },
                    }}>
                        <FormLabel>Resource Text</FormLabel>
                        {inputGlobalStyles}
                        <CKEditor
                            editor={ClassicEditor}
                            style={{ height: '400px' }}
                            config={{
                                toolbar: {
                                    items: [ 'heading',
                                        '|',
                                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                        '|',
                                        'bold', 'italic', 'link', 'bulletedList', 'numberedList',
                                        '|',
                                        'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock',
                                        '|',
                                        'todoList', 'outdent', 'indent', 'undo', 'redo' ]
                                },
                                plugins: [
                                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, List, Heading, Indent, MediaEmbed, Table, FontFamily, FontSize, BlockQuote, AutoLink, LinkEditing, LinkUI
                                ],
                                link: {
                                    addTargetToExternalLinks: true,
                                },
                                mediaEmbed: {
                                    previewsInData: true,

                                }
                            }}
                            data={resource?.rsContent}
                            onChange={(event, editor) => {
                                updateResourceContent(editor.getData());
                            }}
                        />
                    </Grid>
                    <Grid item md={12} sx={{marginTop: 4}}>
                        <FormLabel>Image</FormLabel>
                        <br/>
                        <Button variant={'contained'} onClick={() => {setShowAttachmentModal(true); setAttachmentType(resourceConstants.ImageAttachment)}}>{resource?.resourceImage ? 'Change' : 'Select'}</Button>
                        <CardMedia
                            component={'img'}
                            sx={{maxHeight: 200,padding: "1em 1em 0 1em", objectFit: 'contain', marginBottom: '10px'}}
                            image={resource?.resourceImage?.url}
                        />
                    </Grid>
                    <Grid item md={12} sx={{marginTop: 4}}>
                        <FormLabel>Attachment</FormLabel>
                        <br/>
                        <Button variant={'contained'} onClick={() => {setShowAttachmentModal(true); setAttachmentType(resourceConstants.FileAttachment)}}>{resource?.resourceAttachment ? 'Change' : 'Select'}</Button>
                        <FormLabel>{resource?.resourceAttachment?.fileName}</FormLabel>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button variant={'contained'} color={'error'} onClick={cancel}>Cancel</Button>
                    <Button variant={'contained'} onClick={saveResource}>Save</Button>
                </DialogActions>

            </DialogContent>
            <FileUploadModal onClose={handleFileUpload} open={showAttachmentModal} imageButton={true} />
        </Dialog>
    )
}

EditResourceModal.propTypes = {
    resource: PropTypes.objectOf(ResourceDto),
    setResource: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setSnackbarProperties: PropTypes.func,
    types: PropTypes.arrayOf(ResourceTypeDto),
    topics: PropTypes.arrayOf(ResourceTopicDto),
    cancel: PropTypes.func.isRequired,
    resourceApiService: PropTypes.objectOf(ResourceApiService).isRequired,
}
