import Home from './components/Home';
import Tasks from './components/Tasks';
import Queries from './components/Queries';
import Hazards from './components/Hazards';
import Logout from './components/Logout';
import Task from './components/Task';
import Hazard from './components/Hazard';
import PrinterFriendlyTask from './components/Tasks/PrinterFriendlyTask';
import Dashboard from './components/Dashboard';
import React from 'react';
import Users from './components/Users';
import Organizations from './components/Organizations';
import Locations from './components/Locations';
import CustomColumns from './components/CustomColumns';
import Categories from './components/Admin/Categories/Categories';
import Priorities from './components/Admin/Priorities/Priorities';
import Statuses from './components/Admin/Statuses/Statuses';
import AuditChecklistAdmin from './components/AuditChecklistAdmin';
import AuditChecklists from './components/AuditChecklists';
import Resources from './components/Resources';
import Resource from './components/Resource';
import PasswordResetPage from './components/PasswordReset';
import Authorized from './auth/authorized';
import RiskSources from './components/Admin/RiskSource/RiskSources';

const AppRoutes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: '/Tasks',
        requireAuth: true,
        element: <Authorized authorized={<Tasks />} />,
    },
    {
        path: '/Queries',
        requireAuth: true,
        element: <Authorized authorized={<Queries />} />,
    },
    {
        path: '/Hazards',
        requireAuth: true,
        element: <Authorized authorized={<Hazards />} />,
    },
    {
        path: '/Logout',
        requireAuth: true,
        element: <Authorized authorized={<Logout />} />,
    },
    {
        path: '/Task/:taskId',
        requireAuth: true,
        element: <Authorized authorized={<Task />} />,
    },
    {
        path: '/Task',
        requireAuth: true,
        element: <Authorized authorized={<Task />} />,
    },
    {
        path: '/Hazard',
        requireAuth: true,
        element: <Authorized authorized={<Hazard />} />,
    },
    {
        path: '/Hazard/:hazardId',
        requireAuth: true,
        element: <Authorized authorized={<Hazard />}/>,
    },
    {
        path: '/PrintTask/:taskId',
        requireAuth: true,
        element: <Authorized authorized={<PrinterFriendlyTask />} />,
    },
    {
        path: '/Dashboard',
        requireAuth: true,
        element: <Authorized authorized={<Dashboard />} />,
    },
    {
        path: '/AuditChecklists',
        requireAuth: true,
        element: <Authorized authorized={<AuditChecklists />} />,
    },
    {
        path: '/admin/Users',
        requireAuth: true,
        element: <Authorized authorized={<Users />} />,
    },
    {
        path: '/admin/Organizations',
        requireAuth: true,
        element: <Authorized authorized={<Organizations />} />,
    },
    {
        path: '/admin/Locations',
        requireAuth: true,
        element: <Authorized authorized={<Locations />} />,
    },
    {
        path: '/admin/CustomColumns',
        requireAuth: true,
        element: <Authorized authorized={<CustomColumns />} />,
    },
    {
        path: '/admin/Categories',
        requireAuth: true,
        element: <Authorized authorized={<Categories />} />,
    },
    {
        path: '/admin/Priorities',
        requireAuth: true,
        element: <Authorized authorized={<Priorities />} />,
    },
    {
        path: '/admin/Statuses',
        requireAuth: true,
        element: <Authorized authorized={<Statuses />} />,
    },
    {
        path: '/admin/AuditChecklistAdmin',
        requireAuth: true,
        element: <Authorized authorized={<AuditChecklistAdmin />} />,
    },
    {
        path: '/resources',
        requireAuth: true,
        element: <Authorized authorized={<Resources />} />,
    },
    {
        path: '/resource/:resourceId',
        requireAuth: true,
        element: <Authorized authorized={<Resource />} />,
    },
    {
        path: '/admin/RiskSource',
        requireAuth: true,
        element: <Authorized authorized={<RiskSources />} />
    },
    {
        path: '/password-reset/:token',
        requireAuth: false,
        element: <PasswordResetPage />,
    },
];

export default AppRoutes;
