import React, { useEffect, useState } from 'react';
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, FormControl } from '@mui/material';
import LocationApiService from '../api/LocationApiService';
import EditMenuButton from './Modal/EditMenuButton';
import EditLocationModal from './Modal/Admin/EditLocationModal';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';

const locationApiService = new LocationApiService();

const Locations = (props) => {
    const { setSnackbarProperties } = props;
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [currentSelectedLocation, setCurrentSelectedLocation] = useState();
    const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'pjId',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'ogName',
            headerName: 'Organization',
            flex: 1.5,
            valueGetter: (params) => params.row.pjOrgDto?.ogName
        },
        {
            field: 'pjName',
            headerName: 'Location',
            flex: 1.5,
        },
        {
            field: 'pjActive',
            headerName: 'Active',
            flex: 1.5,
            renderCell: (params) => params.row.pjActive > 0 ? 'Y' : 'N'
        },
        {
            field: 'pjDefault',
            headerName: 'Default',
            flex: 1.5,
            renderCell: (params) => params.row.pjDefault > 0 ? 'Y' : 'N'
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setCurrentSelectedLocation}
                    setShowEditDialog={setOpenEditLocationModal}
                    onEditFunc={() => {
                        setOpenEditLocationModal(true);
                        setCurrentSelectedLocation(params.row);
                    }}
                />;
            },
        },
    ];

    useEffect(() => {
        async function setupLocations () {
            setLoading(true);
            await populateLocations();
            setLoading(false);
        }

        setupLocations();
    }, []);

    async function populateLocations () {
        let locationResponse = await locationApiService.getLocations();
        setLocations(locationResponse.data);
    }

    const dialogTitle = () => {
        return 'Confirm Delete Location ' + currentSelectedLocation?.pjName;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Location ' + currentSelectedLocation?.pjName + '?';
    };

    async function onDeleteLocationClick (deleteValue) {
        if (deleteValue) {
            let deleteLocationResponse = await locationApiService.deleteLocation(currentSelectedLocation);
            if (deleteLocationResponse.success === true)
            {
                setSnackbarProperties('Successfully deleted Location ' + currentSelectedLocation.pjName);
                await populateLocations();
            }
        }

        setShowDeleteDialog(false);
    };

    return (
        <div>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Locations
            </Typography>
            <FormControl sx={{ marginBottom: '8px' }}>
                <Button size={'large'} variant="contained" onClick={() => setOpenEditLocationModal(true)} > Create new Location</Button>
            </FormControl>
            <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                <DataGridPro
                    rows={locations}
                    columns={columns}
                    loading={loading}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.pjId}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    getRowHeight={() => 'auto'}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                />
            </Box>
            <EditLocationModal
                open={openEditLocationModal}
                currentLocation={currentSelectedLocation}
                setSnackbarProperties={setSnackbarProperties}
                onCancel={() => {
                    setOpenEditLocationModal(false);
                    setCurrentSelectedLocation(null);
                }}
                onSave={async () => {
                    await populateLocations();
                    setOpenEditLocationModal(false);
                    setCurrentSelectedLocation(null);
                }}
            />
            <ConfirmDeleteDialogHazard
                id="confirm-delete"
                keepMounted
                value={currentSelectedLocation}
                open={showDeleteDialog}
                onClose={onDeleteLocationClick}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </div>
    );
};
export default Locations;
