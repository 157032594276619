import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditMenuButton from '../../Modal/EditMenuButton';
import PropTypes from 'prop-types';
import ConfirmDeleteDialog from '../../Modal/ConfirmDelete';
import { Row } from 'reactstrap';
import { Box, Button, FormControl } from '@mui/material';
import ModifyRiskSourceModal from './ModifyRiskSourceModal';
import RiskSourceApiService from '../../../api/RiskSourceApiService';

const RiskSources = (props) => {
    const { setSnackbarProperties } = props;

    const [riskSources, setRiskSources] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRiskSource, setSelectedRiskSource] = useState();

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'name',
            headerName: 'Risk Source',
            flex: 2,
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setSelectedRiskSource}
                    onEditFunc={() => {
                        setShowEditDialog(true);
                        setSelectedRiskSource(params.row)
                    }}
                />;
            },
        }
    ];

    const riskSourceApiService = new RiskSourceApiService();

    async function getRiskSources () {
        const riskSources = await riskSourceApiService.getRiskSources();
        setRiskSources(riskSources.data ?? []);
    }

    async function onDeleteClick(value) {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }

        if (selectedRiskSource) {
            const deletedRiskSource = await riskSourceApiService.deleteRiskSource(selectedRiskSource.id);
            if (deletedRiskSource && deletedRiskSource.success) {
                setSnackbarProperties('Deleted Risk Source', 'warning');
                await getRiskSources();
            }
        }

        setShowDeleteDialog(false);
    }

    async function handleEditClose() {
        setShowEditDialog(false);
        setSelectedRiskSource(null);
        await getRiskSources();
    }

    useEffect(() => {
        void getRiskSources();
    }, [])

    return(
        <div>
            <Row>
                <h2>Risk Sources</h2>
            </Row>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'left' }}>
                        <Button size={'large'} variant="contained" onClick={() => setShowEditDialog(true) }>Create new Risk Source</Button>
                    </FormControl>
                </div>
            </Row>
            <Box sx={{ height: '80vh', width: '100%', display: 'block'}}>
                <DataGridPro
                    columns={columns}
                    rows={riskSources}
                    getRowId={(row) => row.id}
                    getRowHeight={() => 'auto'}
                />
            </Box>
            <ConfirmDeleteDialog
                onClose={onDeleteClick}
                open={showDeleteDialog}
            />
            <ModifyRiskSourceModal
                open={showEditDialog}
                onClose={handleEditClose}
                selectedRiskSource={selectedRiskSource}
                setSnackbarProperties={setSnackbarProperties}
                riskSourceApiService={riskSourceApiService}
                onCancel={handleEditClose}
            />
        </div>
    );
}

RiskSources.propTypes = {
    setSnackbarProperties: PropTypes.func,
}
export default RiskSources;
