import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AuthUtil from '../components/Utils/AuthUtil';

export default function Authorized (props) {
    const navigate = useNavigate();

    const { setSnackbarProperties } = props;

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isAuthorized = useMemo(() => AuthUtil.isAuthorized(userToken), [userToken]);

    useEffect(() => {
        if(!isAuthorized) {
            navigate('/');
        }
    }, []);

    return (
        <>
            {isAuthorized && React.cloneElement(props.authorized, {setSnackbarProperties})}
        </>
    );
}

Authorized.propTypes = {
    authorized: PropTypes.element.isRequired,
    setSnackbarProperties: PropTypes.func,
};
