import React, { useEffect, useState } from 'react';
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, FormControl } from '@mui/material';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';
import CustomColumnApiService from '../api/CustomColumnApiService';
import EditMenuButton from './Modal/EditMenuButton';
import EditCustomColumnModal from './Modal/Admin/EditCustomColumnModal';

const customColumnApiService = new CustomColumnApiService();

const CustomColumns = (props) => {
    const { setSnackbarProperties } = props;
    const [customColumns, setCustomColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [currentSelectedCustomColumn, setCurrentSelectedCustomColumn] = useState();
    const [openEditCustomColumnModal, setOpenEditCustomColumnModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'customColumnId',
            headerName: 'Id',
            flex: 0.25,
        },
        {
            field: 'customColumnName',
            headerName: 'Name',
            flex: 0.5,
        },
        {
            field: 'dataTypeFix',
            headerName: 'Datatype',
            flex: 0.5,
        },
        {
            field: 'dataTypeLength',
            headerName: 'Length',
            flex: 0.5,
        },
        {
            field: 'inputType',
            headerName: 'Input Type',
            flex: 0.5,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
        },
        {
            field: 'options',
            headerName: 'Options',
            flex: 1.5,
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setCurrentSelectedCustomColumn}
                    setShowEditDialog={setOpenEditCustomColumnModal}
                    onEditFunc={() => {
                        setOpenEditCustomColumnModal(true);
                        setCurrentSelectedCustomColumn(params.row);
                    }}
                />;
            },
        },
    ];

    function transformRows (rows) {
        return rows.map((row) => {
            let { datatype } = row;
            let commaIndex = datatype.indexOf(',');
            let dataTypeFix = commaIndex > -1 ? datatype.substring(0, commaIndex) : datatype;
            let dataTypeLength = commaIndex > -1 ? datatype.substring(commaIndex + 1).trim() : '';

            return { ...row, dataTypeFix, dataTypeLength };
        });
    };

    useEffect(() => {
        async function setupCustomColumns () {
            setLoading(true);
            await populateCustomColumns();
            setLoading(false);
        }

        setupCustomColumns();
    }, []);

    async function populateCustomColumns () {
        let customColumnsResponse = await customColumnApiService.getCustomColumns();
        setCustomColumns(customColumnsResponse.data);
    }

    const dialogTitle = () => {
        return 'Confirm Delete Custom Column ' + currentSelectedCustomColumn?.customColumnName;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Custom Column ' + currentSelectedCustomColumn?.customColumnName + '?';
    };

    async function onDeleteLocationClick (deleteValue) {
        if (deleteValue) {
            let deleteCustomColumnResponse = await customColumnApiService.deleteCustomColumn(currentSelectedCustomColumn);
            if (deleteCustomColumnResponse.success === true)
            {
                setSnackbarProperties('Successfully deleted Custom Column ' + currentSelectedCustomColumn.customColumnName);
                await populateCustomColumns();
            }
        }

        setShowDeleteDialog(false);
        setCurrentSelectedCustomColumn(null);
    };

    return (
        <div>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Custom Columns
            </Typography>
            <FormControl sx={{ marginBottom: '8px' }}>
                <Button size={'large'} variant="contained" onClick={() => setOpenEditCustomColumnModal(true)} > Create new Custom Column</Button>
            </FormControl>
            <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                <DataGridPro
                    rows={transformRows(customColumns)}
                    columns={columns}
                    loading={loading}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.customColumnId}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                />
            </Box>
            <EditCustomColumnModal
                open={openEditCustomColumnModal}
                currentCustomColumn={currentSelectedCustomColumn}
                setSnackbarProperties={setSnackbarProperties}
                onCancel={() => {
                    setOpenEditCustomColumnModal(false);
                    setCurrentSelectedCustomColumn(null);
                }}
                onSave={async () => {
                    await populateCustomColumns();
                    setOpenEditCustomColumnModal(false);
                    setCurrentSelectedCustomColumn(null);
                }}
            />
            <ConfirmDeleteDialogHazard
                keepMounted
                value={currentSelectedCustomColumn}
                open={showDeleteDialog}
                onClose={onDeleteLocationClick}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </div>
    );
};
export default CustomColumns;
