import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {Box, Button, TextField} from '@mui/material';
import authService from '../auth/AuthService';
import PasswordResetSubmissionDTO from '../dtos/Users/PasswordResetSubmissionDto';
import {useNavigate, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthUtil from './Utils/AuthUtil';

export default function PasswordResetPage(props) {
    const {setSnackbarProperties, isMobile, isTablet} = props;
    const params = useParams();
    const token = params.token;

    const[password, setPassword] = useState('');
    const[passwordError, setPasswordError] = useState(false);
    const[confirmPassword, setConfirmPassword] = useState('');
    const[confirmPasswordError, setConfirmPasswordError] = useState(false);
    const[loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const desktopFieldSx = {marginBottom: '20px'};
    const mobileFieldSx = {marginBottom: '20px', width: '100%'};

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isAuthorized = useMemo(() => AuthUtil.isAuthorized(userToken), [userToken]);

    function validate() {
        if(password.length < 6) {
            setSnackbarProperties('Password must be at least 6 characters long', 'error');
            setPasswordError(true);
            return false;
        }

        if(password !== confirmPassword) {
            setSnackbarProperties('Passwords must match', 'error');
            setConfirmPasswordError(true);
            return false;
        }

        return true;
    }

    async function resetPassword() {
        if(validate()) {
            const resetSubmission = new PasswordResetSubmissionDTO();

            resetSubmission.password = password;
            resetSubmission.token = token.toString();
            setLoading(true);
            const resetResponse = await authService.submitChangedPassword(resetSubmission);

            if(resetResponse.success) {
                setSnackbarProperties('Successfully changed password');
                navigate('/');
            } else {
                setSnackbarProperties(`Error resetting password: ${resetResponse.message ? resetResponse.message : resetResponse}`, 'error');
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        if(isAuthorized) {
            navigate('/Tasks');
        }

        document.title = 'CorrectTrack - Reset Password';
    }, []);

    return(
        <Box>
            <div className="custom-logo">
            </div>
            <div className="row">
                <div className="col-md-4 pb-3 pt-3 offset-md-4 text-center">
                    <div className="logo">
                        <div className="title"><em></em></div>
                    </div>
                </div>
            </div>
            <center>
                <h1>Reset Your Password</h1>
                <br/>
                <TextField
                    type={'password'}
                    sx={isMobile || isTablet ? mobileFieldSx : desktopFieldSx}
                    label={'New Password'}
                    error={passwordError}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <br/>
                <TextField
                    type={'password'}
                    sx={isMobile || isTablet ? mobileFieldSx : desktopFieldSx}
                    label={'Confirm New Password'}
                    error={confirmPasswordError}
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <br/>
                <Button style={{width: 250}} variant={'contained'} onClick={resetPassword}>Submit</Button>
            </center>

        </Box>
    );
}

PasswordResetPage.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
};
