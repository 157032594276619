import Box from '@mui/material/Box';
import ResourceApiService from '../api/ResourceApiService';
import { useEffect, useMemo, useState } from 'react';
import { Backdrop, Chip, Grid, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ResourcePreview from './ResourceComponents/ResourcePreview';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import ConfirmDeleteDialog from './Modal/ConfirmDelete';
import EditResourceModal from './Modal/Resources/EditResourceModal';
import ResourceDto from '../dtos/Resources/ResourceDto';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import AuthUtil from './Utils/AuthUtil';

export default function Resources(props) {
    const { setSnackbarProperties } = props;

    const[resources, setResources] = useState([]);
    const[originalResources, setOriginalResources] = useState([]);
    const[topics, setTopics] = useState([]);
    const[types, setTypes] = useState([]);
    const[typeFilter, setTypeFilter] = useState('');
    const[topicFilter, setTopicFilter] = useState('');
    const[showDeleteDialog, setShowDeleteDialog] = useState(false);
    const[selectedResource, setSelectedResource] = useState();
    const[showEditDialog, setShowEditDialog] = useState(false);
    const[loading, setLoading] = useState(false);

    const resourceApiService = new ResourceApiService();

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isSuperAdmin = useMemo(() => AuthUtil.isSuperAdmin(userToken), [userToken]);
    const isAdmin = useMemo(() => AuthUtil.isAdmin(userToken), [userToken]);
    function applyTopicFilter(applyFilter) {
        setTopicFilter('');
        if(applyFilter === topicFilter) {
            if(typeFilter) {
                setResources(originalResources.filter((resource) => {
                    return resource.rsResourceType.ryName === typeFilter;
                }));
            } else {
                setResources(originalResources);
            }
        } else {
            setTopicFilter(applyFilter);
            let currentResources = typeFilter ? originalResources.filter((resource) => {
                return resource.rsResourceType.ryName === typeFilter;
                }) : originalResources;
            setResources(currentResources.filter((resource) => {
                return resource.resourceTopics.filter(topic => topic.name === applyFilter).length > 0;
            }));
        }
    }

    function applyTypeFilter(applyFilter) {
        if(applyFilter === typeFilter) {
            setTypeFilter('');
            if(topicFilter) {
                setResources(originalResources.filter((resource) => {
                    return resource.resourceTopics.filter(topic => topic.name === topicFilter).length > 0;
                }));
            } else {
                setResources(originalResources);
            }
        } else {
            setTypeFilter(applyFilter);
            let currentResources = topicFilter ? originalResources.filter((resource) => {
                return resource.resourceTopics.filter(topic => topic.name === topicFilter).length > 0;
                }) : originalResources;
            setResources(currentResources.filter((resource) => {
                return resource.rsResourceType.ryName === applyFilter;
            }));
        }
    }

    async function onDeleteClick(value) {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }

        if (selectedResource) {
            const deletedResource = await resourceApiService.deleteResource(selectedResource.rsId);
            if (deletedResource && deletedResource.success) {
                setSnackbarProperties('Deleted Resource', 'warning');
                await loadResources();
            }
        }

        setSelectedResource(new ResourceDto());
        setShowDeleteDialog(false);
    }

    function confirmDeleteResource(resource) {
        setSelectedResource(resource);
        setShowDeleteDialog(true);
    }

    function editResource(resource) {
        setSelectedResource(resource);
        setShowEditDialog(true);
    }

    function createNewResource() {
        setSelectedResource(new ResourceDto());
        setShowEditDialog(true);
    }

    function cancelEdit() {
        setShowEditDialog(false);
        setSelectedResource(new ResourceDto());
        loadResources();
    }

    function getNumberOfItemsWithTopic(topicName) {
        return originalResources.filter((value) => value.resourceTopics.some((topic) => topic.name === topicName)).length;
    }

    function getNumberOfItemsWithType(typeName) {
        return originalResources.filter((value) => value.rsResourceType.ryName === typeName).length;
    }

    async function loadResources() {
        setLoading(true);
        const resourcesResponse = await resourceApiService.getResources();
        const topicsResponse = await resourceApiService.getTopics();
        const typesResponse = await resourceApiService.getTypes();

        if(resourcesResponse.success) {
            setResources(resourcesResponse.data);
            setOriginalResources(resourcesResponse.data);
        } else {
            setSnackbarProperties('Error retrieving resources: ' + resourcesResponse.message, 'error');
        }

        if(topicsResponse.success) {
            setTopics(topicsResponse.data);
        } else {
            setSnackbarProperties('Error retrieving topics: ' + topicsResponse.message, 'error');
        }

        if(typesResponse.success) {
            setTypes(typesResponse.data);
        } else {
            setSnackbarProperties('Error retrieving types: ' + typesResponse.message, 'error');
        }
        setLoading(false);
    }

    useEffect(() => {
        loadResources();
    }, []);
    return (
        <Box>
            <h1>Resources</h1>
            {(isAdmin || isSuperAdmin)
                && <Button variant={'contained'} onClick={createNewResource}>Add New Resource</Button>
            }
            <br/><br/>
            {loading ? <Backdrop open={loading}><CircularProgress /></Backdrop> :
                <Grid container columnSpacing={0.5}>
                    <Grid container item xs={2.5} direction={'column'} sx={{marginRight: '10px'}}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{background: '#4D5061'}}>
                                    <TableRow>
                                        <TableCell sx={{color: 'white'}}>Topic</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {topics.sort(function (a, b) { return a.rtSort - b.rtSort;}).map((topic) => {
                                        return <TableRow
                                            key={topic.id}
                                            className={topicFilter === topic.name ? 'resource-filter resource-filter-selected' : 'resource-filter'}
                                            onClick={() => applyTopicFilter(topic.name)}
                                        >
                                            <TableCell className={'table-flex'} sx={{display: 'flex'}}>
                                                <p className={'left-aligned-text'}>{topic.name}</p>
                                                <Chip className={'right-aligned-text'} variant={'outlined'} label={getNumberOfItemsWithTopic(topic.name)}/>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer component={Paper} sx={{marginTop: '10px'}}>
                            <Table>
                                <TableHead sx={{background: '#4D5061'}}>
                                    <TableRow>
                                        <TableCell sx={{color: 'white'}}>Types</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {types.map((type) => {
                                        return <TableRow
                                            key={type.ryId}
                                            className={typeFilter === type.ryName ? 'resource-filter resource-filter-selected' : 'resource-filter'}
                                            onClick={() => applyTypeFilter(type.ryName)}
                                        >
                                            <TableCell sx={{display: 'flex'}}>
                                                <p className={'left-aligned-text'}>{type.ryName}</p>
                                                <Chip className={'right-aligned-text'} variant={'outlined'} label={getNumberOfItemsWithType(type.ryName)} />
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container item xs={9} spacing={3} >
                        {resources.map((resource) => {
                            return <Grid item key={resource.rsId}>
                                <ResourcePreview
                                    resource={resource}
                                    confirmDeleteResource={confirmDeleteResource}
                                    editResource={editResource}
                                />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            }


            <ConfirmDeleteDialog onClose={onDeleteClick} open={showDeleteDialog} />
            <EditResourceModal
                resource={selectedResource}
                setResource={setSelectedResource}
                open={showEditDialog}
                setSnackbarProperties={setSnackbarProperties}
                types={types}
                topics={topics}
                resourceApiService={resourceApiService}
                cancel={cancelEdit}
            />
        </Box>
    )
}
