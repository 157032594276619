import React, { useEffect, useMemo, useState } from 'react';
import authService from '../auth/AuthService';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import PasswordResetModal from './Modal/PasswordResetModal';
import PropTypes from 'prop-types';
import AuthUtil from './Utils/AuthUtil';
import { useSelector } from 'react-redux';

const Home = (props) => {
    const navigate = useNavigate();
    const {setSnackbarProperties} = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [sendingResetPassword, setSendingResetPassword] = useState(false);

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isAuthorized = useMemo(() => AuthUtil.isAuthorized(userToken), [userToken]);

    async function handleSubmit () {
        setLoggingIn(true);
        if (validate()) {
            const result = await authService.login(username, password);
            if (result.data.success) {
                redirectToTaskPage();
            } else {
                setPassword('');
                setLoginError(result.data.message);
            }
        }

        setLoggingIn(false);
    }

    function redirectToTaskPage () {
        navigate('/Tasks', { replace: true });
    }

    function handleEnterKey(event) {
        if(event.key === 'Enter') {
            handleSubmit();
        }
    }

    function validate () {
        if (username === '') {
            setUsernameError('Username must be entered');
        } else {
            setUsernameError('');
        }
        if (password === '') {
            setPasswordError('Password must be entered');
        } else {
            setPasswordError('');
        }

        if (usernameError || passwordError) {
            setLoginError('');
            return false;
        }

        return true;
    }

    function closeResetModal() {
        setShowResetPassword(false);
    }

    async function handleResetPasswordRequest(username) {
        setSendingResetPassword(true);
        const response = await authService.requestResetPassword(username);

        if(response.success) {
            setSnackbarProperties(response.message);
        } else if(response.message){
            setSnackbarProperties(response.message, 'error');
        } else {
            setSnackbarProperties(response, 'error');
        }
        setSendingResetPassword(false);
        closeResetModal();
    }

    useEffect(() => {
        if(isAuthorized) {
            navigate('/Dashboard');
        }
    }, []);

    return (<div className={'login'}>
        <div className="custom-logo">
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-4 pb-3 pt-3 offset-md-4 text-center">
                    <div className="logo">
                        <div className="title"><em></em></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 login">
                    <div className="card">
                        <div className="card-header bg-primary text-light">
                            Login
                        </div>

                        <form id="account">
                            <span className="text-danger">{loginError}</span>
                            <div className="form-group">
                                <label className="col-form-label">Username</label>
                                <input
                                    name="username"
                                    id="username"
                                    className="form-control"
                                    onKeyUp={handleEnterKey}
                                    autoComplete="username"
                                    value={username}
                                    onChange={(newValue) => {
                                        setUsername(newValue.target.value);
                                    }}
                                    placeholder="Username"
                                    aria-required="true"/>
                                <span className="text-danger">{usernameError}</span>
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Password</label>
                                <input
                                    className="form-control"
                                    value={password}
                                    onKeyUp={handleEnterKey}
                                    onChange={(newValue) => {
                                        setPassword(newValue.target.value);
                                    }}
                                    name="password" aria-required="true" placeholder="Password"
                                    type={'password'}/>
                                <span className="text-danger">{passwordError}</span>
                            </div>
                            <span className="text-danger" id="msg">&nbsp;</span>
                            <div style={{display: 'flex'}}>
                                <div className={'text-left'} style={{marginLeft: 5, marginRight: 'auto', width: '35%'}}>
                                    <Button variant={'contained'} color={'error'} sx={{width: '100%'}} onClick={() => setShowResetPassword(true)}>Reset Password</Button>
                                </div>
                                <div className="text-right" style={{width: '35%'}}>
                                    <Button disabled={loggingIn}
                                                   onClick={handleSubmit}
                                                   variant="contained"
                                                   sx={{width: '100%'}}
                                    >
                                        {loggingIn ? 'Loading' : 'Login'}
                                    </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <PasswordResetModal
            open={showResetPassword}
            close={closeResetModal}
            loading={sendingResetPassword}
            handleSubmitReset={handleResetPasswordRequest}
        />
    </div>);
};

Home.propTypes = {
    setSnackbarProperties: PropTypes.func,
};

export default Home;
