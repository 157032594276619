import React, { useEffect, useState } from 'react';
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, FormControl } from '@mui/material';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';
import EditMenuButton from './Modal/EditMenuButton';
import AuditChecklistApiService from '../api/AuditChecklistApiService';
import EditAuditChecklistModal from './Modal/Admin/EditAuditChecklistModal';

const auditChecklistApiService = new AuditChecklistApiService();

const AuditChecklistAdmin = (props) => {
    const { setSnackbarProperties } = props;
    const [auditChecklists, setAuditChecklists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [currentSelectedAuditChecklist, setCurrentSelectedAuditChecklist] = useState();
    const [openEditAuditChecklistModal, setOpenEditAuditChecklistModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'acId',
            headerName: 'Id',
            flex: .25,
        },
        {
            field: 'acTitle',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'organizationName',
            headerName: 'Organization',
            flex: 1,
        },
        {
            field: 'projectName',
            headerName: 'Location',
            flex: 1,
        },
        {
            field: 'acIsActive',
            headerName: 'Active',
            flex: .5,
            renderCell: (params) => params.row.acIsActive > 0 ? 'Y' : 'N'
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.25,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setCurrentSelectedAuditChecklist}
                    setShowEditDialog={setOpenEditAuditChecklistModal}
                    onEditFunc={() => {
                        setOpenEditAuditChecklistModal(true);
                        setCurrentSelectedAuditChecklist(params.row);
                    }}
                />;
            },
        },
    ];

    useEffect(() => {
        async function setupAuditChecklists () {
            await populateAuditChecklists();
        }

        void setupAuditChecklists();
    }, []);

    async function populateAuditChecklists () {
        setLoading(true);
        let auditChecklistResponse = await auditChecklistApiService.getAuditChecklists();
        setAuditChecklists(auditChecklistResponse.data);
        setLoading(false);
    }

    const dialogTitle = () => {
        return 'Confirm Delete Audit Checklist ' + currentSelectedAuditChecklist?.acTitle;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Audit Checklist ' + currentSelectedAuditChecklist?.acTitle + '?';
    };

    async function onDeleteLocationClick (deleteValue) {
        if (deleteValue) {
            let deleteAuditChecklistResponse = await auditChecklistApiService.deleteAuditChecklist(currentSelectedAuditChecklist);

            if (deleteAuditChecklistResponse.success === true)
            {
                setSnackbarProperties('Successfully deleted Audit Checklist ' + currentSelectedAuditChecklist.acTitle);

                await populateAuditChecklists();
            }

            setShowDeleteDialog(false);
            setCurrentSelectedAuditChecklist(null);
        }
    };

    return (
        <div>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Audit Checklists
            </Typography>
            <FormControl sx={{ marginBottom: '8px' }}>
                <Button size={'large'} variant="contained" onClick={() => setOpenEditAuditChecklistModal(true)} > Create new Audit Checklist</Button>
            </FormControl>
            <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                <DataGridPro
                    rows={auditChecklists}
                    columns={columns}
                    loading={loading}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.acId}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                />
            </Box>
            <EditAuditChecklistModal
                open={openEditAuditChecklistModal}
                currentAuditChecklist={currentSelectedAuditChecklist}
                setSnackbarProperties={setSnackbarProperties}
                onCancel={() => {
                    setOpenEditAuditChecklistModal(false);
                    setCurrentSelectedAuditChecklist(null);
                }}
                onSave={async () => {
                    await populateAuditChecklists();
                    setOpenEditAuditChecklistModal(false);
                    setCurrentSelectedAuditChecklist(null);
                }}
            />
            <ConfirmDeleteDialogHazard
                keepMounted
                value={currentSelectedAuditChecklist}
                open={showDeleteDialog}
                onClose={onDeleteLocationClick}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </div>
    );
};
export default AuditChecklistAdmin;
