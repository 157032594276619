import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button } from 'reactstrap';
import React, { useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    AutoLink,
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials, FontFamily, FontSize,
    Heading,
    Indent,
    Italic,
    Link, LinkEditing, LinkUI,
    List, MediaEmbed,
    Mention,
    Paragraph, Table,
    Undo,
} from 'ckeditor5';
import { TextField } from '@mui/material';

export default function EditCommentModal (props) {
    const {open, cancel, comment, setComment, save, originalValue, useEditor} = props;

    useEffect(() => {

    }, []);

    return (
        <Dialog
            open={open}
            sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
            >
            <DialogTitle>Edit Comment</DialogTitle>
            <DialogContent dividers>
                {useEditor ?
                    <CKEditor
                        editor={ClassicEditor}
                        style={{height: '400px', width: '100%'}}
                        config={{
                            toolbar: {
                                items: [ 'heading',
                                    '|',
                                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                    '|',
                                    'bold', 'italic', 'link', 'bulletedList', 'numberedList',
                                    '|',
                                    'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock',
                                    '|',
                                    'todoList', 'outdent', 'indent', 'undo', 'redo' ]
                            },
                            plugins: [
                                Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, List, Heading, Indent, MediaEmbed, Table, FontFamily, FontSize, BlockQuote, AutoLink, LinkEditing, LinkUI
                            ],
                            link: {
                                addTargetToExternalLinks: true,
                            },
                            mediaEmbed: {
                                previewsInData: true,

                            }
                        }}
                        data={comment}
                        onChange={(event, editor) => {
                            setComment(editor.getData());
                        }}
                    />
                :
                <TextField style={{ width: '100%' }} value={comment} onChange={(event) => {
                        setComment(event.target.value);
                        }
                    } />
                    }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => cancel()}>Cancel</Button>
                <Button onClick={save} disabled={originalValue === comment}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
