import { Card, CardActions, CardHeader, CardMedia, Chip, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import ResourceDto from '../../dtos/Resources/ResourceDto';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import AuthUtil from '../Utils/AuthUtil';

export default function ResourcePreview(props) {
    const { resource, confirmDeleteResource, editResource } = props;

    const navigate = useNavigate();

    const userToken = useSelector((state) => state.correcttrack.userToken);
    const isSuperAdmin = useMemo(() => AuthUtil.isSuperAdmin(userToken), [userToken]);
    const isAdmin = useMemo(() => AuthUtil.isAdmin(userToken), [userToken]);

    return (
        <Grid item md={12} >
            <Card sx={{maxWidth: 300, margin: "0 auto", padding: "0.1em"}}>
                <CardHeader
                    title={resource.rsTitle}
                />
                {resource.resourceImage &&
                    <CardMedia
                        component={'img'}
                        sx={{maxHeight: 200,padding: "1em 1em 0 1em", objectFit: 'contain', marginBottom: '10px'}}
                        image={resource.resourceImage.url}
                    />
                }

                <Typography variant={"body2"} color={'text.secondary'} sx={{ margin: '0 10px'}}>
                    {resource.rsShortDescription}
                </Typography>
                <Chip variant={'outlined'} label={resource.rsResourceType.ryName} />
                {resource.resourceTopics.map((value) => {
                    return <Chip key={value.id} label={value.name} />
                })}
                <CardActions>
                    <Button size={'small'} onClick={() => navigate('/resource/' + resource.rsId)}>Read more</Button>
                    {(isSuperAdmin || isAdmin) &&
                        <div className={'resource-admin-buttons'}>
                        <Button size={'small'} color={'success'} onClick={() => { editResource(resource) }}>Edit</Button>
                        <Button size={'small'} color={'error'} onClick={() => { confirmDeleteResource(resource)}}>Delete</Button>
                        </div>
                    }
                </CardActions>
            </Card>
        </Grid>
    )
}

ResourcePreview.propTypes = {
    resource: PropTypes.objectOf(ResourceDto),
    confirmDeleteResource: PropTypes.func.isRequired,
    editResource: PropTypes.func.isRequired,
}
