import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import TaskApiService from '../api/TaskApiService';

const taskApiService = new TaskApiService();

const Dashboard = () => {
    const [statuses, setStatuses] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [userTasks, setUserTasks] = useState([]);
    const [userTasksColumns, setUserTasksColumns] = useState([]);

    const COLORS = ['#d88487', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658', '#ffa726'];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        taskApiService.getTaskStatuses().then((taskStatuses) => {
            setStatuses(taskStatuses);

            // User Tasks relies on the statuses to exist for display, so set them with the statuses response
            setUserTasksColumns([{
                field: 'id',
                headerName: 'ID',
                flex: 1,
                renderCell: (params) => <Link to={`/Task/${params.value}`}>{params.value}</Link>,
            },
            { field: 'shortDescription', headerName: 'Description', flex: 3 },
            {
                field: 'statusId',
                headerName: 'Status',
                flex: 3,
                renderCell: (params) => {
                    return taskStatuses.find((status) => status.stId === params.value)?.stName;
                },
            },
            ]);
        });

        taskApiService.getTaskByStatus().then((taskByStatus) => {
            setTasks(taskByStatus);
        });

        taskApiService.getTaskUser().then((user) => {
            setUserTasks(user);
        });
    }, []);

    const getStatusNameById = (id) => {
        const status = statuses.find((status) => status.stId === id);
        return status ? status.stName : id;
    };

    return (
        <div style={{ display: 'flex', alignItems: 'stretch' }}className="grid-container">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Task Summary</Typography>
                    <PieChart width={600} height={400}>
                        <Pie
                            dataKey="tasks"
                            data={tasks}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label={(entry) => getStatusNameById(entry.statusId) + ` (${entry.tasks})`}
                        >
                            {tasks.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index]}/>))}
                        </Pie>
                        <Tooltip/>
                        <Legend formatter={(value, entry) => `${getStatusNameById(entry.payload.statusId)}`}/>
                    </PieChart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">User Assigned Tasks</Typography>
                    <DataGridPro
                        rows={userTasks}
                        localeText={{ noRowsLabel: 'No Tasks currently assigned' }}
                        columns={userTasksColumns}
                        getRowHeight={() => 'auto'}
                        getRowId={(row) => row.id}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pagination
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
